<template>
  <layout>
    <nuxt />
  </layout>
</template>

<script>
import Layout from '~/components/layout/mainLayout'
export default {
  components: {
    Layout
  }
}
</script>
