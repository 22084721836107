<template>
  <div class="layout">
    <div class="logo-wrapper">
      <a href="https://creditsaison.in/">
        <el-image class="logo" :src="logoUrl" fit="contain">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </a>
    </div>
    <el-main class="main-container">
      <slot />
    </el-main>
    <div class="social-icons-wrapper">
      <a :href="linkedIn.url" target="_blank">
        <el-button type="text-black" size="small" btn-min-width="false">
          <el-image class="icon" :src="linkedIn.iconUrl" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-button>
      </a>
      <a :href="medium.url" target="_blank">
        <el-button type="text-black" size="small" btn-min-width="false">
          <el-image class="icon" :src="medium.iconUrl" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-button>
      </a>
    </div>
    <!-- <Footer class="only-desktop" /> -->
  </div>
</template>

<script>
// import Footer from '~/components/layout/footer'
export default {
  // components: { Footer },
  data() {
    return {
      logoUrl: require('~/assets/images/CS India Logo.svg'),
      linkedIn: {
        iconUrl: require('~/assets/images/linked_In.svg'),
        url: 'https://www.linkedin.com/company/creditsaison-india/'
      },
      medium: {
        iconUrl: require('~/assets/images/medium.svg'),
        url: 'https://medium.com/credit-saison-india'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/theme/color';
@import '~/assets/scss/main';

$--z-index: 3;

.main-container {
  padding: 0;
}

.logo-wrapper {
  position: fixed;
  top: 10px;
  left: 32px;
  z-index: 3;
  @include for-phone-portrait {
    top: 20px;
    left: 20px;
  }
  @include for-phone-landscape {
    top: 20px;
    left: 20px;
  }
  .logo {
    height: 60px;
    width: 77.76px;
    @include for-phone-portrait {
      height: 30px;
      width: 40px;
    }
    @include for-phone-landscape {
      height: 30px;
      width: 40px;
    }
  }
}

.social-icons-wrapper {
  position: fixed;
  bottom: 100px;
  left: 38px;
  font-size: 20px;
  z-index: $--z-index;
  @include for-phone-portrait {
    left: -12px;
  }
  @include for-phone-landscape {
    left: 3px;
    bottom: 50px;
  }
  a {
    display: block;
    margin-bottom: 12px;
    .icon {
      height: 20px;
      width: 20px;
    }
  }
}

.layout .only-desktop {
  @include for-phone-portrait {
    display: none;
  }
  @include for-phone-landscape {
    display: none;
  }
  @include for-tablet-portrait {
    display: none;
  }
}

::v-deep .social-icons-wrapper .el-button--text-black:focus,
::v-deep .footer .el-button--text-black:focus {
  color: #363840;
  border-color: transparent;
  background-color: transparent;
}
</style>
