<template>
  <div class="container">
    <el-image class="image" :src="errorType.url" fit="contain">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <h3 class="heading">{{ errorType.heading }}</h3>
    <p class="text">{{ errorType.text }}</p>
    <nuxt-link to="/">
      <el-link type="primary">Back home</el-link>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      notFound: {
        url: require('~/assets/images/404.svg'),
        heading: 'Page not found',
        text: 'Sorry, we can’t find the page that you’re looking for.'
      },
      serverError: {
        url: require('~/assets/images/500.svg'),
        heading: 'Internal Server Error',
        text:
          'Sorry, we’re experiencing an internal server problem. Please try again after sometime.'
      }
    }
  },
  computed: {
    errorType() {
      if (this.error.statusCode === 500) return this.serverError
      return this.notFound
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/theme/color';
@import '~/assets/scss/main';

.container {
  height: 100vh;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $--color-nero;
  text-align: center;
}
.image {
  margin-bottom: 40px;
  @include for-phone-portrait {
    width: 60%;
  }
  @include for-phone-landscape {
    width: 40%;
    margin-bottom: 16px;
  }
}
.heading {
  margin: 0;
}
.text {
  width: 350px;
  margin: 16px 0;
  font-size: 13px;
  @include for-phone-portrait {
    width: 80%;
  }
  @include for-phone-landscape {
    margin: 0;
    width: 80%;
  }
}
</style>
