import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _17d8b9d1 = () => interopDefault(import('../pages/05th-agm-notice.vue' /* webpackChunkName: "pages/05th-agm-notice" */))
const _58bcc034 = () => interopDefault(import('../pages/1st-annual-general-meeting.vue' /* webpackChunkName: "pages/1st-annual-general-meeting" */))
const _4e681edf = () => interopDefault(import('../pages/1st-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/1st-extraordinary-general-meeting" */))
const _7dc97f5e = () => interopDefault(import('../pages/2nd-annual-general-meeting.vue' /* webpackChunkName: "pages/2nd-annual-general-meeting" */))
const _a1704716 = () => interopDefault(import('../pages/2nd-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/2nd-extraordinary-general-meeting" */))
const _d826dd1c = () => interopDefault(import('../pages/3rd-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/3rd-extraordinary-general-meeting" */))
const _2b75d4b5 = () => interopDefault(import('../pages/4th-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/4th-extraordinary-general-meeting" */))
const _3f1da836 = () => interopDefault(import('../pages/5th-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/5th-extraordinary-general-meeting" */))
const _52c57bb7 = () => interopDefault(import('../pages/6th-extraordinary-general-meeting.vue' /* webpackChunkName: "pages/6th-extraordinary-general-meeting" */))
const _47027ce7 = () => interopDefault(import('../pages/annual-return-mgt-7-2022.vue' /* webpackChunkName: "pages/annual-return-mgt-7-2022" */))
const _4e101062 = () => interopDefault(import('../pages/annual-return-mgt-7-fy-23.vue' /* webpackChunkName: "pages/annual-return-mgt-7-fy-23" */))
const _4e1e27e3 = () => interopDefault(import('../pages/annual-return-mgt-7-fy-24.vue' /* webpackChunkName: "pages/annual-return-mgt-7-fy-24" */))
const _2152295f = () => interopDefault(import('../pages/annual-return-MGT-9_2019.vue' /* webpackChunkName: "pages/annual-return-MGT-9_2019" */))
const _22882e75 = () => interopDefault(import('../pages/annual-return-MGT-9_2020.vue' /* webpackChunkName: "pages/annual-return-MGT-9_2020" */))
const _280d4666 = () => interopDefault(import('../pages/boards-report-2020.vue' /* webpackChunkName: "pages/boards-report-2020" */))
const _281b5de7 = () => interopDefault(import('../pages/boards-report-2021.vue' /* webpackChunkName: "pages/boards-report-2021" */))
const _28297568 = () => interopDefault(import('../pages/boards-report-2022.vue' /* webpackChunkName: "pages/boards-report-2022" */))
const _28378ce9 = () => interopDefault(import('../pages/boards-report-2023.vue' /* webpackChunkName: "pages/boards-report-2023" */))
const _de84423e = () => interopDefault(import('../pages/code-for-independent-directors.vue' /* webpackChunkName: "pages/code-for-independent-directors" */))
const _3760db8c = () => interopDefault(import('../pages/consolidated-financials-ksf-31-03-2024.vue' /* webpackChunkName: "pages/consolidated-financials-ksf-31-03-2024" */))
const _5c8a4740 = () => interopDefault(import('../pages/contactUs/index.vue' /* webpackChunkName: "pages/contactUs/index" */))
const _7e1d4474 = () => interopDefault(import('../pages/corporate-governance-policy.vue' /* webpackChunkName: "pages/corporate-governance-policy" */))
const _63b56b4a = () => interopDefault(import('../pages/corporate-social-responsibility-policy.vue' /* webpackChunkName: "pages/corporate-social-responsibility-policy" */))
const _450e108e = () => interopDefault(import('../pages/covid-resolution-framework/index.vue' /* webpackChunkName: "pages/covid-resolution-framework/index" */))
const _65455ceb = () => interopDefault(import('../pages/customer-awareness-on-sma-and-npa-classification/index.vue' /* webpackChunkName: "pages/customer-awareness-on-sma-and-npa-classification/index" */))
const _47bd77ff = () => interopDefault(import('../pages/equal-opportunity-and-anti-discrimination-policy.vue' /* webpackChunkName: "pages/equal-opportunity-and-anti-discrimination-policy" */))
const _7674234c = () => interopDefault(import('../pages/ex-gratia-interest-relief-scheme.vue' /* webpackChunkName: "pages/ex-gratia-interest-relief-scheme" */))
const _942b2c40 = () => interopDefault(import('../pages/fair-practice-code-assamese.vue' /* webpackChunkName: "pages/fair-practice-code-assamese" */))
const _97eeaf48 = () => interopDefault(import('../pages/fair-practice-code-bengali.vue' /* webpackChunkName: "pages/fair-practice-code-bengali" */))
const _a5fa4102 = () => interopDefault(import('../pages/fair-practice-code-gujarati.vue' /* webpackChunkName: "pages/fair-practice-code-gujarati" */))
const _051226cc = () => interopDefault(import('../pages/fair-practice-code-hindi.vue' /* webpackChunkName: "pages/fair-practice-code-hindi" */))
const _3004347c = () => interopDefault(import('../pages/fair-practice-code-kannada.vue' /* webpackChunkName: "pages/fair-practice-code-kannada" */))
const _76b12fa1 = () => interopDefault(import('../pages/fair-practice-code-malayalam.vue' /* webpackChunkName: "pages/fair-practice-code-malayalam" */))
const _10c4444c = () => interopDefault(import('../pages/fair-practice-code-marathi.vue' /* webpackChunkName: "pages/fair-practice-code-marathi" */))
const _26713036 = () => interopDefault(import('../pages/fair-practice-code-odiya.vue' /* webpackChunkName: "pages/fair-practice-code-odiya" */))
const _e5eb8b86 = () => interopDefault(import('../pages/fair-practice-code-tamil.vue' /* webpackChunkName: "pages/fair-practice-code-tamil" */))
const _7377f89e = () => interopDefault(import('../pages/fair-practice-code-telugu.vue' /* webpackChunkName: "pages/fair-practice-code-telugu" */))
const _2322c12d = () => interopDefault(import('../pages/fair-practices-code/index.vue' /* webpackChunkName: "pages/fair-practices-code/index" */))
const _460c0572 = () => interopDefault(import('../pages/financials-ksf-31-03-2019.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2019" */))
const _47420a88 = () => interopDefault(import('../pages/financials-ksf-31-03-2020.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2020" */))
const _47502209 = () => interopDefault(import('../pages/financials-ksf-31-03-2021.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2021" */))
const _475e398a = () => interopDefault(import('../pages/financials-ksf-31-03-2022.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2022" */))
const _476c510b = () => interopDefault(import('../pages/financials-ksf-31-03-2023.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2023" */))
const _477a688c = () => interopDefault(import('../pages/financials-ksf-31-03-2024.vue' /* webpackChunkName: "pages/financials-ksf-31-03-2024" */))
const _0b338b24 = () => interopDefault(import('../pages/form-mgt-7-2021.vue' /* webpackChunkName: "pages/form-mgt-7-2021" */))
const _4364c79a = () => interopDefault(import('../pages/grievance-redressal-policy/index.vue' /* webpackChunkName: "pages/grievance-redressal-policy/index" */))
const _5766d217 = () => interopDefault(import('../pages/grievance-redressal-policy-assamese.vue' /* webpackChunkName: "pages/grievance-redressal-policy-assamese" */))
const _0bd2dd45 = () => interopDefault(import('../pages/grievance-redressal-policy-bengali.vue' /* webpackChunkName: "pages/grievance-redressal-policy-bengali" */))
const _4e7f47b6 = () => interopDefault(import('../pages/grievance-redressal-policy-gujarati.vue' /* webpackChunkName: "pages/grievance-redressal-policy-gujarati" */))
const _688681f5 = () => interopDefault(import('../pages/grievance-redressal-policy-hindi.vue' /* webpackChunkName: "pages/grievance-redressal-policy-hindi" */))
const _3fc81aab = () => interopDefault(import('../pages/grievance-redressal-policy-kannada.vue' /* webpackChunkName: "pages/grievance-redressal-policy-kannada" */))
const _04c1ce4a = () => interopDefault(import('../pages/grievance-redressal-policy-malayalam.vue' /* webpackChunkName: "pages/grievance-redressal-policy-malayalam" */))
const _688e7935 = () => interopDefault(import('../pages/grievance-redressal-policy-marathi.vue' /* webpackChunkName: "pages/grievance-redressal-policy-marathi" */))
const _ec34e942 = () => interopDefault(import('../pages/grievance-redressal-policy-odiya.vue' /* webpackChunkName: "pages/grievance-redressal-policy-odiya" */))
const _1f02d534 = () => interopDefault(import('../pages/grievance-redressal-policy-tamil.vue' /* webpackChunkName: "pages/grievance-redressal-policy-tamil" */))
const _7e8f0295 = () => interopDefault(import('../pages/grievance-redressal-policy-telugu.vue' /* webpackChunkName: "pages/grievance-redressal-policy-telugu" */))
const _0b281fc2 = () => interopDefault(import('../pages/group-safeguard-insurance-policy-wordings.vue' /* webpackChunkName: "pages/group-safeguard-insurance-policy-wordings" */))
const _39fdb4a2 = () => interopDefault(import('../pages/icici-lombard-annexure.vue' /* webpackChunkName: "pages/icici-lombard-annexure" */))
const _5c677c00 = () => interopDefault(import('../pages/icici-lombard-terms-and-conditons.vue' /* webpackChunkName: "pages/icici-lombard-terms-and-conditons" */))
const _1ebff7fd = () => interopDefault(import('../pages/income-protect-and-gsg-terms-and-conditions.vue' /* webpackChunkName: "pages/income-protect-and-gsg-terms-and-conditions" */))
const _25f734e5 = () => interopDefault(import('../pages/income-protect-policy-wordings.vue' /* webpackChunkName: "pages/income-protect-policy-wordings" */))
const _7a590788 = () => interopDefault(import('../pages/insurance/index.vue' /* webpackChunkName: "pages/insurance/index" */))
const _0d95f554 = () => interopDefault(import('../pages/integrated-ombudsman-scheme/index.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme/index" */))
const _a8ba4d54 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-assamese.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-assamese" */))
const _eb2d1bb4 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-bengali.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-bengali" */))
const _ba896216 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-gujarati.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-gujarati" */))
const _d06249d4 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-hindi.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-hindi" */))
const _8342a0e8 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-kannada.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-kannada" */))
const _3806aeeb = () => interopDefault(import('../pages/integrated-ombudsman-scheme-malayalam.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-malayalam" */))
const _31b5e3d4 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-marathi.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-marathi" */))
const _8da43700 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-odiya.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-odiya" */))
const _1fc6ee87 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-tamil.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-tamil" */))
const _3851cd94 = () => interopDefault(import('../pages/integrated-ombudsman-scheme-telugu.vue' /* webpackChunkName: "pages/integrated-ombudsman-scheme-telugu" */))
const _895588e0 = () => interopDefault(import('../pages/interest-rate-policy/index.vue' /* webpackChunkName: "pages/interest-rate-policy/index" */))
const _74d748f5 = () => interopDefault(import('../pages/ksf-annual-report-2023-24.vue' /* webpackChunkName: "pages/ksf-annual-report-2023-24" */))
const _a912b88c = () => interopDefault(import('../pages/ksf-public-disclosure-on-liquidity-risk-dec-2022.vue' /* webpackChunkName: "pages/ksf-public-disclosure-on-liquidity-risk-dec-2022" */))
const _2c3436a9 = () => interopDefault(import('../pages/ksf-public-disclosure-on-liquidity-risk-june-2023.vue' /* webpackChunkName: "pages/ksf-public-disclosure-on-liquidity-risk-june-2023" */))
const _2c424e2a = () => interopDefault(import('../pages/ksf-public-disclosure-on-liquidity-risk-june-2024.vue' /* webpackChunkName: "pages/ksf-public-disclosure-on-liquidity-risk-june-2024" */))
const _1eb8735b = () => interopDefault(import('../pages/ksf-public-disclosure-on-liquidity-risk-march-2024.vue' /* webpackChunkName: "pages/ksf-public-disclosure-on-liquidity-risk-march-2024" */))
const _777d6648 = () => interopDefault(import('../pages/nach-mandate-cancellation-form/index.vue' /* webpackChunkName: "pages/nach-mandate-cancellation-form/index" */))
const _20d1bf3c = () => interopDefault(import('../pages/nomination-and-renumeration-policy.vue' /* webpackChunkName: "pages/nomination-and-renumeration-policy" */))
const _549ed1e4 = () => interopDefault(import('../pages/notice-of-04th-agm.vue' /* webpackChunkName: "pages/notice-of-04th-agm" */))
const _323451aa = () => interopDefault(import('../pages/notice-of-07th-egm.vue' /* webpackChunkName: "pages/notice-of-07th-egm" */))
const _7bca89ec = () => interopDefault(import('../pages/notice-of-08th-egm.vue' /* webpackChunkName: "pages/notice-of-08th-egm" */))
const _e3763d62 = () => interopDefault(import('../pages/operational-guidelines.vue' /* webpackChunkName: "pages/operational-guidelines" */))
const _f4672146 = () => interopDefault(import('../pages/pocket-insurance-policy-wordings.vue' /* webpackChunkName: "pages/pocket-insurance-policy-wordings" */))
const _6fec5686 = () => interopDefault(import('../pages/pocket-insurance-terms-and-conditions.vue' /* webpackChunkName: "pages/pocket-insurance-terms-and-conditions" */))
const _89e8930e = () => interopDefault(import('../pages/policy-for-appointment-of-statutory-auditors.vue' /* webpackChunkName: "pages/policy-for-appointment-of-statutory-auditors" */))
const _0f1b870b = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _62eca47f = () => interopDefault(import('../pages/privacy-policy-of-privo.vue' /* webpackChunkName: "pages/privacy-policy-of-privo" */))
const _1dc53f3f = () => interopDefault(import('../pages/privo-terms-conditions/index.vue' /* webpackChunkName: "pages/privo-terms-conditions/index" */))
const _307ef6b5 = () => interopDefault(import('../pages/public-disclosure-on-liquidity-risk-december-2023.vue' /* webpackChunkName: "pages/public-disclosure-on-liquidity-risk-december-2023" */))
const _b1a90252 = () => interopDefault(import('../pages/public-Disclosure-on-liquidity-risk-june-2022.vue' /* webpackChunkName: "pages/public-Disclosure-on-liquidity-risk-june-2022" */))
const _0442da8a = () => interopDefault(import('../pages/public-Disclosure-on-liquidity-risk-march-2022.vue' /* webpackChunkName: "pages/public-Disclosure-on-liquidity-risk-march-2022" */))
const _a7026baa = () => interopDefault(import('../pages/public-disclosure-on-liquidity-risk-march-2023.vue' /* webpackChunkName: "pages/public-disclosure-on-liquidity-risk-march-2023" */))
const _4db9d2bc = () => interopDefault(import('../pages/public-Disclosure-on-liquidity-risk-september-2022.vue' /* webpackChunkName: "pages/public-Disclosure-on-liquidity-risk-september-2022" */))
const _05e69b46 = () => interopDefault(import('../pages/public-disclosure-on-liquidity-risk-september-2023.vue' /* webpackChunkName: "pages/public-disclosure-on-liquidity-risk-september-2023" */))
const _bd4fe116 = () => interopDefault(import('../pages/rbi-integrated-ombudsman-scheme-nov-2021.vue' /* webpackChunkName: "pages/rbi-integrated-ombudsman-scheme-nov-2021" */))
const _67f72dcc = () => interopDefault(import('../pages/regulatory/index.vue' /* webpackChunkName: "pages/regulatory/index" */))
const _2770565b = () => interopDefault(import('../pages/related-party-transaction-policy.vue' /* webpackChunkName: "pages/related-party-transaction-policy" */))
const _2440d8d7 = () => interopDefault(import('../pages/resignation-letter-of-akihiro-matsuda.vue' /* webpackChunkName: "pages/resignation-letter-of-akihiro-matsuda" */))
const _d4cf8cfc = () => interopDefault(import('../pages/resignation-letter-of-amit-dutta.vue' /* webpackChunkName: "pages/resignation-letter-of-amit-dutta" */))
const _72d79802 = () => interopDefault(import('../pages/resignation-letter-of-jitendra-gupta.vue' /* webpackChunkName: "pages/resignation-letter-of-jitendra-gupta" */))
const _4ecb4f6b = () => interopDefault(import('../pages/resignation-letter-of-ravi-neeladri.vue' /* webpackChunkName: "pages/resignation-letter-of-ravi-neeladri" */))
const _20e9b383 = () => interopDefault(import('../pages/resignation-letter-of-sanjeev-sharma.vue' /* webpackChunkName: "pages/resignation-letter-of-sanjeev-sharma" */))
const _f84642b2 = () => interopDefault(import('../pages/resignation-letter-of-yasuyuki-isobe.vue' /* webpackChunkName: "pages/resignation-letter-of-yasuyuki-isobe" */))
const _a395f776 = () => interopDefault(import('../pages/statutory/index.vue' /* webpackChunkName: "pages/statutory/index" */))
const _786c289c = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _5e6d8b6e = () => interopDefault(import('../pages/third_general_meeting/index.vue' /* webpackChunkName: "pages/third_general_meeting/index" */))
const _6129e40b = () => interopDefault(import('../pages/vendor-termination-list.vue' /* webpackChunkName: "pages/vendor-termination-list" */))
const _148a75ff = () => interopDefault(import('../pages/whistleblower-policy.vue' /* webpackChunkName: "pages/whistleblower-policy" */))
const _d584541e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/05th-agm-notice",
    component: _17d8b9d1,
    name: "05th-agm-notice"
  }, {
    path: "/1st-annual-general-meeting",
    component: _58bcc034,
    name: "1st-annual-general-meeting"
  }, {
    path: "/1st-extraordinary-general-meeting",
    component: _4e681edf,
    name: "1st-extraordinary-general-meeting"
  }, {
    path: "/2nd-annual-general-meeting",
    component: _7dc97f5e,
    name: "2nd-annual-general-meeting"
  }, {
    path: "/2nd-extraordinary-general-meeting",
    component: _a1704716,
    name: "2nd-extraordinary-general-meeting"
  }, {
    path: "/3rd-extraordinary-general-meeting",
    component: _d826dd1c,
    name: "3rd-extraordinary-general-meeting"
  }, {
    path: "/4th-extraordinary-general-meeting",
    component: _2b75d4b5,
    name: "4th-extraordinary-general-meeting"
  }, {
    path: "/5th-extraordinary-general-meeting",
    component: _3f1da836,
    name: "5th-extraordinary-general-meeting"
  }, {
    path: "/6th-extraordinary-general-meeting",
    component: _52c57bb7,
    name: "6th-extraordinary-general-meeting"
  }, {
    path: "/annual-return-mgt-7-2022",
    component: _47027ce7,
    name: "annual-return-mgt-7-2022"
  }, {
    path: "/annual-return-mgt-7-fy-23",
    component: _4e101062,
    name: "annual-return-mgt-7-fy-23"
  }, {
    path: "/annual-return-mgt-7-fy-24",
    component: _4e1e27e3,
    name: "annual-return-mgt-7-fy-24"
  }, {
    path: "/annual-return-MGT-9_2019",
    component: _2152295f,
    name: "annual-return-MGT-9_2019"
  }, {
    path: "/annual-return-MGT-9_2020",
    component: _22882e75,
    name: "annual-return-MGT-9_2020"
  }, {
    path: "/boards-report-2020",
    component: _280d4666,
    name: "boards-report-2020"
  }, {
    path: "/boards-report-2021",
    component: _281b5de7,
    name: "boards-report-2021"
  }, {
    path: "/boards-report-2022",
    component: _28297568,
    name: "boards-report-2022"
  }, {
    path: "/boards-report-2023",
    component: _28378ce9,
    name: "boards-report-2023"
  }, {
    path: "/code-for-independent-directors",
    component: _de84423e,
    name: "code-for-independent-directors"
  }, {
    path: "/consolidated-financials-ksf-31-03-2024",
    component: _3760db8c,
    name: "consolidated-financials-ksf-31-03-2024"
  }, {
    path: "/contactUs",
    component: _5c8a4740,
    name: "contactUs"
  }, {
    path: "/corporate-governance-policy",
    component: _7e1d4474,
    name: "corporate-governance-policy"
  }, {
    path: "/corporate-social-responsibility-policy",
    component: _63b56b4a,
    name: "corporate-social-responsibility-policy"
  }, {
    path: "/covid-resolution-framework",
    component: _450e108e,
    name: "covid-resolution-framework"
  }, {
    path: "/customer-awareness-on-sma-and-npa-classification",
    component: _65455ceb,
    name: "customer-awareness-on-sma-and-npa-classification"
  }, {
    path: "/equal-opportunity-and-anti-discrimination-policy",
    component: _47bd77ff,
    name: "equal-opportunity-and-anti-discrimination-policy"
  }, {
    path: "/ex-gratia-interest-relief-scheme",
    component: _7674234c,
    name: "ex-gratia-interest-relief-scheme"
  }, {
    path: "/fair-practice-code-assamese",
    component: _942b2c40,
    name: "fair-practice-code-assamese"
  }, {
    path: "/fair-practice-code-bengali",
    component: _97eeaf48,
    name: "fair-practice-code-bengali"
  }, {
    path: "/fair-practice-code-gujarati",
    component: _a5fa4102,
    name: "fair-practice-code-gujarati"
  }, {
    path: "/fair-practice-code-hindi",
    component: _051226cc,
    name: "fair-practice-code-hindi"
  }, {
    path: "/fair-practice-code-kannada",
    component: _3004347c,
    name: "fair-practice-code-kannada"
  }, {
    path: "/fair-practice-code-malayalam",
    component: _76b12fa1,
    name: "fair-practice-code-malayalam"
  }, {
    path: "/fair-practice-code-marathi",
    component: _10c4444c,
    name: "fair-practice-code-marathi"
  }, {
    path: "/fair-practice-code-odiya",
    component: _26713036,
    name: "fair-practice-code-odiya"
  }, {
    path: "/fair-practice-code-tamil",
    component: _e5eb8b86,
    name: "fair-practice-code-tamil"
  }, {
    path: "/fair-practice-code-telugu",
    component: _7377f89e,
    name: "fair-practice-code-telugu"
  }, {
    path: "/fair-practices-code",
    component: _2322c12d,
    name: "fair-practices-code"
  }, {
    path: "/financials-ksf-31-03-2019",
    component: _460c0572,
    name: "financials-ksf-31-03-2019"
  }, {
    path: "/financials-ksf-31-03-2020",
    component: _47420a88,
    name: "financials-ksf-31-03-2020"
  }, {
    path: "/financials-ksf-31-03-2021",
    component: _47502209,
    name: "financials-ksf-31-03-2021"
  }, {
    path: "/financials-ksf-31-03-2022",
    component: _475e398a,
    name: "financials-ksf-31-03-2022"
  }, {
    path: "/financials-ksf-31-03-2023",
    component: _476c510b,
    name: "financials-ksf-31-03-2023"
  }, {
    path: "/financials-ksf-31-03-2024",
    component: _477a688c,
    name: "financials-ksf-31-03-2024"
  }, {
    path: "/form-mgt-7-2021",
    component: _0b338b24,
    name: "form-mgt-7-2021"
  }, {
    path: "/grievance-redressal-policy",
    component: _4364c79a,
    name: "grievance-redressal-policy"
  }, {
    path: "/grievance-redressal-policy-assamese",
    component: _5766d217,
    name: "grievance-redressal-policy-assamese"
  }, {
    path: "/grievance-redressal-policy-bengali",
    component: _0bd2dd45,
    name: "grievance-redressal-policy-bengali"
  }, {
    path: "/grievance-redressal-policy-gujarati",
    component: _4e7f47b6,
    name: "grievance-redressal-policy-gujarati"
  }, {
    path: "/grievance-redressal-policy-hindi",
    component: _688681f5,
    name: "grievance-redressal-policy-hindi"
  }, {
    path: "/grievance-redressal-policy-kannada",
    component: _3fc81aab,
    name: "grievance-redressal-policy-kannada"
  }, {
    path: "/grievance-redressal-policy-malayalam",
    component: _04c1ce4a,
    name: "grievance-redressal-policy-malayalam"
  }, {
    path: "/grievance-redressal-policy-marathi",
    component: _688e7935,
    name: "grievance-redressal-policy-marathi"
  }, {
    path: "/grievance-redressal-policy-odiya",
    component: _ec34e942,
    name: "grievance-redressal-policy-odiya"
  }, {
    path: "/grievance-redressal-policy-tamil",
    component: _1f02d534,
    name: "grievance-redressal-policy-tamil"
  }, {
    path: "/grievance-redressal-policy-telugu",
    component: _7e8f0295,
    name: "grievance-redressal-policy-telugu"
  }, {
    path: "/group-safeguard-insurance-policy-wordings",
    component: _0b281fc2,
    name: "group-safeguard-insurance-policy-wordings"
  }, {
    path: "/icici-lombard-annexure",
    component: _39fdb4a2,
    name: "icici-lombard-annexure"
  }, {
    path: "/icici-lombard-terms-and-conditons",
    component: _5c677c00,
    name: "icici-lombard-terms-and-conditons"
  }, {
    path: "/income-protect-and-gsg-terms-and-conditions",
    component: _1ebff7fd,
    name: "income-protect-and-gsg-terms-and-conditions"
  }, {
    path: "/income-protect-policy-wordings",
    component: _25f734e5,
    name: "income-protect-policy-wordings"
  }, {
    path: "/insurance",
    component: _7a590788,
    name: "insurance"
  }, {
    path: "/integrated-ombudsman-scheme",
    component: _0d95f554,
    name: "integrated-ombudsman-scheme"
  }, {
    path: "/integrated-ombudsman-scheme-assamese",
    component: _a8ba4d54,
    name: "integrated-ombudsman-scheme-assamese"
  }, {
    path: "/integrated-ombudsman-scheme-bengali",
    component: _eb2d1bb4,
    name: "integrated-ombudsman-scheme-bengali"
  }, {
    path: "/integrated-ombudsman-scheme-gujarati",
    component: _ba896216,
    name: "integrated-ombudsman-scheme-gujarati"
  }, {
    path: "/integrated-ombudsman-scheme-hindi",
    component: _d06249d4,
    name: "integrated-ombudsman-scheme-hindi"
  }, {
    path: "/integrated-ombudsman-scheme-kannada",
    component: _8342a0e8,
    name: "integrated-ombudsman-scheme-kannada"
  }, {
    path: "/integrated-ombudsman-scheme-malayalam",
    component: _3806aeeb,
    name: "integrated-ombudsman-scheme-malayalam"
  }, {
    path: "/integrated-ombudsman-scheme-marathi",
    component: _31b5e3d4,
    name: "integrated-ombudsman-scheme-marathi"
  }, {
    path: "/integrated-ombudsman-scheme-odiya",
    component: _8da43700,
    name: "integrated-ombudsman-scheme-odiya"
  }, {
    path: "/integrated-ombudsman-scheme-tamil",
    component: _1fc6ee87,
    name: "integrated-ombudsman-scheme-tamil"
  }, {
    path: "/integrated-ombudsman-scheme-telugu",
    component: _3851cd94,
    name: "integrated-ombudsman-scheme-telugu"
  }, {
    path: "/interest-rate-policy",
    component: _895588e0,
    name: "interest-rate-policy"
  }, {
    path: "/ksf-annual-report-2023-24",
    component: _74d748f5,
    name: "ksf-annual-report-2023-24"
  }, {
    path: "/ksf-public-disclosure-on-liquidity-risk-dec-2022",
    component: _a912b88c,
    name: "ksf-public-disclosure-on-liquidity-risk-dec-2022"
  }, {
    path: "/ksf-public-disclosure-on-liquidity-risk-june-2023",
    component: _2c3436a9,
    name: "ksf-public-disclosure-on-liquidity-risk-june-2023"
  }, {
    path: "/ksf-public-disclosure-on-liquidity-risk-june-2024",
    component: _2c424e2a,
    name: "ksf-public-disclosure-on-liquidity-risk-june-2024"
  }, {
    path: "/ksf-public-disclosure-on-liquidity-risk-march-2024",
    component: _1eb8735b,
    name: "ksf-public-disclosure-on-liquidity-risk-march-2024"
  }, {
    path: "/nach-mandate-cancellation-form",
    component: _777d6648,
    name: "nach-mandate-cancellation-form"
  }, {
    path: "/nomination-and-renumeration-policy",
    component: _20d1bf3c,
    name: "nomination-and-renumeration-policy"
  }, {
    path: "/notice-of-04th-agm",
    component: _549ed1e4,
    name: "notice-of-04th-agm"
  }, {
    path: "/notice-of-07th-egm",
    component: _323451aa,
    name: "notice-of-07th-egm"
  }, {
    path: "/notice-of-08th-egm",
    component: _7bca89ec,
    name: "notice-of-08th-egm"
  }, {
    path: "/operational-guidelines",
    component: _e3763d62,
    name: "operational-guidelines"
  }, {
    path: "/pocket-insurance-policy-wordings",
    component: _f4672146,
    name: "pocket-insurance-policy-wordings"
  }, {
    path: "/pocket-insurance-terms-and-conditions",
    component: _6fec5686,
    name: "pocket-insurance-terms-and-conditions"
  }, {
    path: "/policy-for-appointment-of-statutory-auditors",
    component: _89e8930e,
    name: "policy-for-appointment-of-statutory-auditors"
  }, {
    path: "/privacy-policy",
    component: _0f1b870b,
    name: "privacy-policy"
  }, {
    path: "/privacy-policy-of-privo",
    component: _62eca47f,
    name: "privacy-policy-of-privo"
  }, {
    path: "/privo-terms-conditions",
    component: _1dc53f3f,
    name: "privo-terms-conditions"
  }, {
    path: "/public-disclosure-on-liquidity-risk-december-2023",
    component: _307ef6b5,
    name: "public-disclosure-on-liquidity-risk-december-2023"
  }, {
    path: "/public-Disclosure-on-liquidity-risk-june-2022",
    component: _b1a90252,
    name: "public-Disclosure-on-liquidity-risk-june-2022"
  }, {
    path: "/public-Disclosure-on-liquidity-risk-march-2022",
    component: _0442da8a,
    name: "public-Disclosure-on-liquidity-risk-march-2022"
  }, {
    path: "/public-disclosure-on-liquidity-risk-march-2023",
    component: _a7026baa,
    name: "public-disclosure-on-liquidity-risk-march-2023"
  }, {
    path: "/public-Disclosure-on-liquidity-risk-september-2022",
    component: _4db9d2bc,
    name: "public-Disclosure-on-liquidity-risk-september-2022"
  }, {
    path: "/public-disclosure-on-liquidity-risk-september-2023",
    component: _05e69b46,
    name: "public-disclosure-on-liquidity-risk-september-2023"
  }, {
    path: "/rbi-integrated-ombudsman-scheme-nov-2021",
    component: _bd4fe116,
    name: "rbi-integrated-ombudsman-scheme-nov-2021"
  }, {
    path: "/regulatory",
    component: _67f72dcc,
    name: "regulatory"
  }, {
    path: "/related-party-transaction-policy",
    component: _2770565b,
    name: "related-party-transaction-policy"
  }, {
    path: "/resignation-letter-of-akihiro-matsuda",
    component: _2440d8d7,
    name: "resignation-letter-of-akihiro-matsuda"
  }, {
    path: "/resignation-letter-of-amit-dutta",
    component: _d4cf8cfc,
    name: "resignation-letter-of-amit-dutta"
  }, {
    path: "/resignation-letter-of-jitendra-gupta",
    component: _72d79802,
    name: "resignation-letter-of-jitendra-gupta"
  }, {
    path: "/resignation-letter-of-ravi-neeladri",
    component: _4ecb4f6b,
    name: "resignation-letter-of-ravi-neeladri"
  }, {
    path: "/resignation-letter-of-sanjeev-sharma",
    component: _20e9b383,
    name: "resignation-letter-of-sanjeev-sharma"
  }, {
    path: "/resignation-letter-of-yasuyuki-isobe",
    component: _f84642b2,
    name: "resignation-letter-of-yasuyuki-isobe"
  }, {
    path: "/statutory",
    component: _a395f776,
    name: "statutory"
  }, {
    path: "/terms-conditions",
    component: _786c289c,
    name: "terms-conditions"
  }, {
    path: "/third_general_meeting",
    component: _5e6d8b6e,
    name: "third_general_meeting"
  }, {
    path: "/vendor-termination-list",
    component: _6129e40b,
    name: "vendor-termination-list"
  }, {
    path: "/whistleblower-policy",
    component: _148a75ff,
    name: "whistleblower-policy"
  }, {
    path: "/",
    component: _d584541e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
